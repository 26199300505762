import React from 'react';
import './SliderWithSideButtons.css';

import Slider from '@mui/material/Slider';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

function SliderWithSideButtons( { currentPanel, parameter, setParameter, parameterName, 
                                 parameterMinValue, parameterMaxValue, parameterDisplayMultiplicationFactor,
                                 parameterRoundingDP, parameterStepSize, parameterInformation, requestUpdatedData } ) {

    const marksStandard = [
        {
          value: parameterMinValue,
          label: (parameterMinValue * parameterDisplayMultiplicationFactor).toFixed(parameterRoundingDP),
        },
        {
          value: parameter[currentPanel],
          label: (parameter[currentPanel] * parameterDisplayMultiplicationFactor).toFixed(parameterRoundingDP),
        },
        {
          value: parameterMaxValue,
          label: (parameterMaxValue * parameterDisplayMultiplicationFactor).toFixed(parameterRoundingDP),
        },
      ];

    const marksFringeUpper = [
      {
        value: parameterMinValue,
        label: parameterMinValue,
      },
      {
        value: parameter[currentPanel],
        label: (parameter[currentPanel] * parameterDisplayMultiplicationFactor).toFixed(parameterRoundingDP),
      },   
    ];

    const marksFringeLower = [
      {
        value: parameter[currentPanel],
        label: (parameter[currentPanel] * parameterDisplayMultiplicationFactor).toFixed(parameterRoundingDP),
      },
      {
        value: parameterMaxValue,
        label: (parameterMaxValue * parameterDisplayMultiplicationFactor).toFixed(parameterRoundingDP),
      },
    ];

    const GADSlider = styled(Slider)(({ theme }) => ({
      color: '#af292e',
      '& .MuiSlider-thumb': {
        '&:focus, &:hover, &.Mui-active': {
          boxShadow:
          '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
        },
      },
    }));
      
    const handleSliderChange = (event, newValue) => {
      let tempParameter = parameter
      tempParameter[currentPanel] = newValue
      setParameter(tempParameter)
      requestUpdatedData(tempParameter)
      //console.log(parameter)
    }

    const handleSubtractButtonClick = () => {
      let tempParameter = parameter
      if ((parameter[currentPanel] - parameterStepSize) < parameterMinValue) {
        tempParameter[currentPanel] = parameterMinValue 
        setParameter(tempParameter)
      } else {
        tempParameter[currentPanel] = parameter[currentPanel] - parameterStepSize
        setParameter(tempParameter)
        requestUpdatedData(tempParameter)
      }
      //console.log(parameter)
    }

    const handleAddButtonClick = () => {
      let tempParameter = parameter
      if ((parameter[currentPanel] + parameterStepSize) > parameterMaxValue) { 
        tempParameter[currentPanel] = parameterMaxValue 
        setParameter(tempParameter)
      } else {
        tempParameter[currentPanel] = parameter[currentPanel] + parameterStepSize
        setParameter(tempParameter)
        console.log(tempParameter)
        requestUpdatedData(tempParameter)
      }
      //console.log(parameter)
    }


    return (
        <div className="sliderwithsidebuttons__container">

          <div className="sliderwithsidebuttons__title">
            <h4>{parameterName}</h4>
            <Tooltip title={<Typography fontSize={'16px'}>{parameterInformation}</Typography>}>
              <IconButton id="sliderwithsidebuttons__infobutton" >
                <InfoIcon id="sliderwithsidebuttons__infobutton" />
              </IconButton>
            </Tooltip>
          </div>

          <div className="sliderwithsidebuttons__body">
            <RemoveIcon 
              className="sliderwithsidebuttons__sidebutton" 
              id="sliderwithsidebuttons__sidebutton__subtract"
              onClick={handleSubtractButtonClick} 
            />
            { ((parameter[currentPanel] > parameterMinValue + 1 * parameterStepSize) &&
               (parameter[currentPanel]  < parameterMaxValue - 1 * parameterStepSize)) ? (
              <GADSlider
                width="80%"
                value={parameter[currentPanel]}
                step={parameterStepSize}
                min={parameterMinValue}
                max={parameterMaxValue}
                onChange={handleSliderChange}
                marks={marksStandard}
              />
            ) : (parameter[currentPanel] >= parameterMaxValue - 1 * parameterStepSize) ? (
              <GADSlider
                width="80%"
                value={parameter[currentPanel]}
                step={parameterStepSize}
                min={parameterMinValue}
                max={parameterMaxValue}
                onChange={handleSliderChange}
                marks={marksFringeUpper}
              />
            ) : (
              <GADSlider
                width="80%"
                value={parameter[currentPanel]}
                step={parameterStepSize}
                min={parameterMinValue}
                max={parameterMaxValue}
                onChange={handleSliderChange}
                marks={marksFringeLower}
              />
            )}
            <AddIcon
              className="sliderwithsidebuttons__sidebutton"
              id="sliderwithsidebuttons__sidebutton__add"
              onClick={handleAddButtonClick} 
            />
          </div>

        </div>

    )
}

export default SliderWithSideButtons
